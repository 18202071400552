/*
:root {
  --max-width: 1100px;
  --border-radius: 12px;
  --font-mono: ui-monospace, Menlo, Monaco, 'Cascadia Mono', 'Segoe UI Mono',
    'Roboto Mono', 'Oxygen Mono', 'Ubuntu Monospace', 'Source Code Pro',
    'Fira Mono', 'Droid Sans Mono', 'Courier New', monospace;

  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;

  --primary-glow: conic-gradient(
    from 180deg at 50% 50%,
    #16abff33 0deg,
    #0885ff33 55deg,
    #54d6ff33 120deg,
    #0071ff33 160deg,
    transparent 360deg
  );
  --secondary-glow: radial-gradient(
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );

  --tile-start-rgb: 239, 245, 249;
  --tile-end-rgb: 228, 232, 233;
  --tile-border: conic-gradient(
    #00000080,
    #00000040,
    #00000030,
    #00000020,
    #00000010,
    #00000010,
    #00000080
  );

  --callout-rgb: 238, 240, 241;
  --callout-border-rgb: 172, 175, 176;
  --card-rgb: 180, 185, 188;
  --card-border-rgb: 131, 134, 135;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;

    --primary-glow: radial-gradient(rgba(1, 65, 255, 0.4), rgba(1, 65, 255, 0));
    --secondary-glow: linear-gradient(
      to bottom right,
      rgba(1, 65, 255, 0),
      rgba(1, 65, 255, 0),
      rgba(1, 65, 255, 0.3)
    );

    --tile-start-rgb: 2, 13, 46;
    --tile-end-rgb: 2, 5, 19;
    --tile-border: conic-gradient(
      #ffffff80,
      #ffffff40,
      #ffffff30,
      #ffffff20,
      #ffffff10,
      #ffffff10,
      #ffffff80
    );

    --callout-rgb: 20, 20, 20;
    --callout-border-rgb: 108, 108, 108;
    --card-rgb: 100, 100, 100;
    --card-border-rgb: 200, 200, 200;
  }
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

body {
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb));
}

a {
  color: inherit;
  text-decoration: none;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
}
*/

body {
    overflow-x: hidden;
}

@import url('https://fonts.googleapis.com/css2?family=Antonio:wght@100;200;300;400;500;600;700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;1,100;1,200;1,300;1,400;1,500&display=swap');

@font-face {
    font-family: Oswald;
    src: url('../fonts/Oswald-VariableFont_wght.ttf') format('truetype-variations');
    font-display: swap;
}

img {
    user-drag: none;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.MuiPickersPopper-root.MuiPopper-root {
    z-index: 1401;
}

.MuiPopover-root.MuiMenu-root.MuiModal-root {
    z-index: 1400;
}

::-webkit-scrollbar {
    width: 0.375em;
    padding-right: 1em;
}

::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 10px;
}

.carouselContainer::-webkit-scrollbar {
    background-color: #e5e5e5;
    border-radius: 10px;
}

.carouselContainer::-webkit-scrollbar-thumb {
    background-color: #007bff;
    border-radius: 10px;
}

.MuiDataGrid-columnHeader:focus {
    outline: none;
}

.MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within,
.MuiDataGrid-root .MuiDataGrid-columnHeader:focus .MuiDataGrid-sortIcon,
.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within .MuiDataGrid-sortIcon {
    outline: none !important;
    border: none !important;
}

.hide-illustrations .card-image {
    display: none;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        margin-left: 100%;
    }

    to {
        opacity: 1;
        margin-left: 0;
    }
}

.refresh-modal {
    /* Add animation properties */
    animation: fadeIn 0.4s ease-in;
}

.button-container:hover>.MuiButtonBase-root {
    opacity: 1;
}

.workbook-drawer {
    transition: width 0.6s linear;
}

.cards-container {
    transition: opacity 0.8s ease-in;
}

@keyframes voteScore {
    from {
        width: 0;
    }
}

.vote-score {
    animation: voteScore 0.8s ease-in;
}

@keyframes newNotification {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.new-notification {
    animation: newNotification 0.8s ease-in;
}

.no-new-notifications {
    animation: newNotification 1.6s ease-in;
}

.body-scroll-lock {
    overflow-y: visible !important;
}

.custom-modal,
.custom-modal * {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
}

.custom-modal::before,
.custom-modal::after,
.custom-modal *::before,
.custom-modal *::after {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
}

.mglass {
    display: inline-block;
    pointer-events: none;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
}

.button {
    display: inline-block;
    margin: 4px 2px;
    color: black;
    font-size: 14px;
    padding-left: 32px;
    padding-right: 32px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.button:hover {
    transition-duration: 0.4s;
    -moz-transition-duration: 0.4s;
    -webkit-transition-duration: 0.4s;
    -o-transition-duration: 0.4s;
    color: #3d74de !important;
}

.searchbutton {
    position: absolute;
    font-size: 26px;
    width: 100%;
    margin: 0;
    padding: 0;
    top: -3px;
    left: -5px;
}

.search:focus+.searchbutton {
    transition-duration: 0.4s;
    -moz-transition-duration: 0.4s;
    -webkit-transition-duration: 0.4s;
    -o-transition-duration: 0.4s;
    background-color: white;
    color: black;
}

.search {
    position: absolute;
    left: 30px;
    background-color: white;
    outline: none;
    border: none;
    padding: 0;
    width: 0;
    height: 100%;
    z-index: 10;
    transition-duration: 0.4s;
    -moz-transition-duration: 0.4s;
    -webkit-transition-duration: 0.4s;
    -o-transition-duration: 0.4s;
}

.flip-card {
    perspective: 1000px;
}

.flip-card-inner {
    transition: transform 0.4s;
    transform-style: preserve-3d;
}

.flip-card-front,
.flip-card-back {
    position: absolute;
    backface-visibility: hidden;
}

.flip-card-back {
    transform: rotateY(180deg);
}

.searchBox:focus {
    outline: none;
}

@keyframes shake {
    0% {
        transform: translate(1px, 1px) rotate(0deg);
    }

    10% {
        transform: translate(-1px, -2px) rotate(-1deg);
    }

    20% {
        transform: translate(-3px, 0px) rotate(1deg);
    }

    30% {
        transform: translate(3px, 2px) rotate(0deg);
    }

    40% {
        transform: translate(1px, -1px) rotate(1deg);
    }

    50% {
        transform: translate(-1px, 2px) rotate(-1deg);
    }

    60% {
        transform: translate(-3px, 1px) rotate(0deg);
    }

    70% {
        transform: translate(3px, 1px) rotate(-1deg);
    }

    80% {
        transform: translate(-1px, -1px) rotate(1deg);
    }

    90% {
        transform: translate(1px, 2px) rotate(0deg);
    }

    100% {
        transform: translate(1px, -2px) rotate(-1deg);
    }
}

#custom-bubble-tooltip {
    pointer-events: auto;
}